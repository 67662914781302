import React, { FC } from 'react';
import {
  withStyles, IconButton, Typography, InputBase, Slider,
} from '@material-ui/core';
import './DialogHelpers.scss';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { joint_arrow_left, joint_arrow_right } from '@assets/img';
import { Polygon } from '../index';

const styles = (theme: any):{[index:string]:any} => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
interface DialogTitleProps {
  buttonStatic?: boolean,
  id?: string,
  onClose?: any,
  children?: React.ReactChild | React.ReactNode,
  icon?: any,
  polygon?: boolean,
  classes?: any,
}
export const DialogTitle = withStyles(styles)((props : DialogTitleProps) => {
  const {
    children, classes, polygon, onClose, icon, buttonStatic, ...other
  } = props;
  const content = (
    <MuiDialogTitle disableTypography className={buttonStatic ? classes.root.concat(' dialog__static-header') : classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={`${classes.closeButton} dialog-helpers__close-icon ${buttonStatic ? 'dialog__static-icon' : ''}`}
          onClick={onClose}
        >
          {icon || (
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.12536 3.125L26.8754 26.875"
              stroke="#272727"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.8759 3.12449L3.12586 26.8745"
              stroke="#272727"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          )}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
  return (
    polygon === true ? (
      <Polygon
        cornerRadius={25}
        cornerPosition="rightTop"
        bgColor="#fff"
        boxShadow={{
          x: 0, y: 2, blurRadius: 22, color: 'rgba(74, 74, 74, 0.0908818)',
        }}
      >
        {content}
      </Polygon>
    ) : content
  );
});
type DialogActionsProps = {
  children?: React.ReactChild | React.ReactNode;
  id?: string
  // polygon: boolean
}
export const DialogActions: any = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export const CUSlider: any = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 18,
    width: 18,
    border: '3px solid #fff',
    marginTop: -7,
    boxShadow: '0 0 3px -1px rgba(0,0,0,.3)',
    marginLeft: -9,
    '&:focus,&:hover,&$active': {
      boxShadow: '0 0 3px -1px rgba(0,0,0,.3)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 1,
    backgroundColor: '#f4f4f7',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
}))(Slider);

export function TabPanel(props: any) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}
export function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const questionIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#FFE27A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35742 5.37646C5.96028 5.80453 5.76172 6.37011 5.76172 7.07324H6.63086C6.64714 6.65006 6.71387 6.32617 6.83105 6.10156C7.03939 5.69791 7.41536 5.49609 7.95898 5.49609C8.39844 5.49609 8.71338 5.61328 8.90381 5.84766C9.09424 6.08203 9.18945 6.35872 9.18945 6.67773C9.18945 6.9056 9.12435 7.12532 8.99414 7.33691C8.92253 7.45736 8.82813 7.57292 8.71094 7.68359L8.32031 8.06934C7.94596 8.43718 7.70345 8.76351 7.59277 9.04834C7.4821 9.33317 7.42676 9.70833 7.42676 10.1738H8.2959C8.2959 9.76367 8.34228 9.45361 8.43506 9.24365C8.52783 9.03369 8.73047 8.77735 9.04297 8.47461C9.47266 8.05794 9.7583 7.74219 9.8999 7.52734C10.0415 7.3125 10.1123 7.03255 10.1123 6.6875C10.1123 6.11784 9.91944 5.6499 9.53369 5.28369C9.14795 4.91748 8.63444 4.73438 7.99316 4.73438C7.2998 4.73438 6.75456 4.9484 6.35742 5.37646ZM8.37891 10.9844H7.40723V12H8.37891V10.9844Z"
      fill="#272727"
    />
  </svg>
);
export const BTCIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="#F7931A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.69559 5.2575C8.81334 4.4715 8.21447 4.04888 7.39622 3.76688L7.66172 2.70188L7.01372 2.54063L6.75497 3.5775C6.58826 3.53564 6.41723 3.49665 6.24647 3.45773L6.24646 3.45773L6.23559 3.45525L6.49622 2.41162L5.84822 2.25L5.58272 3.31463L5.55438 3.30815C5.42313 3.27814 5.29397 3.24861 5.16872 3.21712L5.16947 3.21375L4.27547 2.99063L4.10297 3.68288C4.10297 3.68288 4.58409 3.79313 4.57397 3.79988C4.83647 3.8655 4.88372 4.03912 4.87584 4.17712L4.57359 5.39025C4.59159 5.39475 4.61484 5.4015 4.64109 5.41162L4.57247 5.39475L4.14872 7.09425C4.11647 7.17375 4.03509 7.29338 3.85134 7.248C3.85809 7.25738 3.38034 7.13062 3.38034 7.13062L3.05859 7.87238L3.90234 8.08275C3.9929 8.1055 4.08246 8.12887 4.17109 8.152L4.17124 8.15204L4.17131 8.15206C4.23601 8.16895 4.30022 8.18571 4.36397 8.202L4.09584 9.279L4.74347 9.44025L5.00897 8.37525C5.18597 8.42287 5.35772 8.46713 5.52572 8.50913L5.26097 9.56962L5.90897 9.73088L6.17709 8.65613C7.28259 8.86538 8.11359 8.781 8.46347 7.78125C8.74547 6.9765 8.44959 6.51188 7.86797 6.20925C8.29172 6.11175 8.61047 5.83312 8.69559 5.2575ZM7.21434 7.33425C7.03099 8.07283 5.87504 7.7675 5.34593 7.62774L5.34582 7.62771C5.29819 7.61513 5.25564 7.60389 5.21934 7.59487L5.57559 6.168C5.6199 6.17908 5.67406 6.19124 5.73543 6.20503C6.28272 6.32798 7.40284 6.57961 7.21434 7.33425ZM5.84533 5.54267C6.28623 5.66039 7.2476 5.91706 7.41497 5.24587C7.58617 4.55938 6.6516 4.35264 6.1951 4.25165C6.14364 4.24027 6.09826 4.23023 6.06122 4.221L5.73872 5.51475C5.76927 5.52236 5.80514 5.53194 5.84533 5.54267Z"
      fill="white"
    />
  </svg>
);
export const LTCIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="#BFBBBB" />
    <path d="M3.91013 7.20525L3.375 7.413L3.633 6.37838L4.1745 6.16088L4.95487 3H6.87825L6.30862 5.3235L6.83737 5.10938L6.58238 6.14062L6.04725 6.35475L5.72925 7.66087H8.625L8.29763 9H3.4695L3.91013 7.20525Z" fill="white" />
  </svg>
);
export const USDtIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="#26A17B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72055 4.28513V5.17238C8.36793 5.2485 9.6058 5.57513 9.6058 5.96625C9.6058 6.35738 8.36793 6.684 6.72055 6.75975V9.60375H5.25318V6.7605C3.60318 6.68438 2.3623 6.35775 2.3623 5.96625C2.3623 5.57475 3.60318 5.24775 5.25318 5.172V4.28513H3.22293V2.93213H8.7508V4.28513H6.72055ZM6.72055 6.51788V6.51863C8.17555 6.45413 9.26118 6.201 9.26118 5.89725C9.26118 5.59425 8.17555 5.34038 6.72055 5.2755V6.26663C6.67818 6.27038 6.4513 6.28913 5.99868 6.28913C5.62143 6.28913 5.34843 6.273 5.25318 6.26625V5.27475C3.79518 5.34 2.70693 5.59388 2.70693 5.89725C2.70693 6.201 3.79518 6.45488 5.25318 6.519V6.51788C5.34693 6.52238 5.61355 6.53363 5.9923 6.53363C6.46668 6.53363 6.6793 6.52088 6.72055 6.51788Z"
      fill="white"
    />
  </svg>
);
export const ETHIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="#627EEA" />
    <path d="M6.18652 1.5V4.82625L8.9979 6.0825L6.18652 1.5Z" fill="white" fillOpacity="0.602" />
    <path d="M6.18675 1.5L3.375 6.0825L6.18675 4.82625V1.5Z" fill="white" />
    <path d="M6.18652 8.23796V10.4981L8.99977 6.60596L6.18652 8.23796Z" fill="white" fillOpacity="0.602" />
    <path d="M6.18675 10.4981V8.23758L3.375 6.60596L6.18675 10.4981Z" fill="white" />
    <path d="M6.18652 7.7149L8.9979 6.08253L6.18652 4.82703V7.7149Z" fill="white" fillOpacity="0.2" />
    <path d="M3.375 6.08253L6.18675 7.7149V4.82703L3.375 6.08253Z" fill="white" fillOpacity="0.602" />
  </svg>
);
export function SampleNextArrow(props: any) {
  const {
    className, style, onClick, img,
  } = props;
  return (
    <img
      src={img || joint_arrow_left}
      alt="left"
      className={`${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      aria-hidden="true"
    />
  );
}
export function SamplePrevArrow(props: any) {
  const {
    className, style, onClick, img,
  } = props;
  return (
    <img
      src={img || joint_arrow_right}
      alt={joint_arrow_right}
      className={`${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      aria-hidden="true"
    />
  );
}
